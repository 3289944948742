import styled from "styled-components";
import Link from "../../components/Link";
import { Connection } from "../../types/connection";
import { PatrimonyAsset as PatrimonyAssetType } from "../../types/patrimony";
import {
  AllPatrimonyCategories,
  AllPatrimonyCategoriesIcons,
  PatrimonyCategory as PatrimonyCategoryType,
} from "../../types/patrimony.enums";
import { currency, formatUpdatedAt } from "../../utils/format.utils";
import { config } from "../../config/config";

const Body = styled.div`
  background-color: var(--white);
  border: var(--border);
  border-radius: 4px;
  .category {
    padding: 16px;
    font-weight: 500;
    display: flex;
    gap: 16px;
    align-items: center;
    svg {
      flex-grow: 0;
      flex-shrink: 0;
    }
    .category-title {
      text-transform: uppercase;
      flex: auto;
    }
    .category-total {
      white-space: nowrap;
    }
  }
  .assets {
    display: flex;
    flex-direction: column;
    .asset {
      border-top: 1px solid var(--gray-100);
      padding: 16px;
      .first-row {
        display: flex;
        gap: 16px;
        align-items: center;
        .asset-title {
          flex: auto;
          white-space: normal;
        }
        .asset-amount {
          white-space: nowrap;
          margin-left: auto;
        }
      }
      .second-row {
        font-size: 12px;
        color: var(--gray-700);
      }
      @media print {
        break-inside: avoid;
      }
    }
  }
  @media (max-width: 600px) {
    .category,
    .first-row {
      font-size: 14px;
    }
  }

  break-inside: avoid;
`;

const UpdatedAtText = styled.div<{ hasError: boolean }>`
  ${({ hasError }) => (hasError ? "color: var(--error-500) !important;" : "")}
`;

const SectionLink = {
  SAVINGS: "epargne",
  REAL_ESTATE: "immobilier",
  CREDITS: "credit",
  OTHERS: "autre",
};
type AssetProps = {
  asset: PatrimonyAssetType;
  connection: Connection | undefined;
};
const PatrimonyAsset = ({ asset, connection }: AssetProps) => {
  const hasError = !!connection?.state;

  return (
    <div
      className="asset"
      style={{ display: "flex", flexDirection: "row", gap: "8px" }}
    >
      {asset.biConnectorUuid && (
        <img
          src={`${config.bi.apiUrl}/logos/${asset.biConnectorUuid}-thumbnail.webp`}
          alt='logo de l"établissement financier'
          width="24"
          height="24"
          onError={(e) => {
            const target = e.target as HTMLImageElement;
            target.onerror = null;
            target.style.display = "none";
          }}
        />
      )}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="first-row">
          <div className="asset-title">{asset.name}</div>
        </div>
        {asset.biUpdatedAt && (
          <UpdatedAtText
            className={`second-row ${hasError ? "bullet" : ""}`}
            hasError={hasError}
          >
            {formatUpdatedAt(asset.biUpdatedAt, hasError)}
          </UpdatedAtText>
        )}
        <div className="asset-title">
          {asset.bankName ? `${asset.bankName} - ${asset.name}` : asset.name}
        </div>
        <div className="asset-amount">{currency(asset.eurValue)}</div>
      </div>

      <div className="asset-amount" style={{ marginLeft: "auto" }}>
        {currency(asset.eurValue)}
      </div>
    </div>
  );
};

type Props = {
  connections?: Connection[];
  category: PatrimonyCategoryType;
  total: number;
  assets: PatrimonyAssetType[];
  index: number;
};
const PatrimonyCategory = ({
  connections,
  category,
  assets,
  total,
  index,
}: Props) => {
  return (
    <Body className={index === 0 ? "first" : ""}>
      <div className="category">
        {AllPatrimonyCategoriesIcons[category]}
        <div className="category-title">{AllPatrimonyCategories[category]}</div>
        <div className="category-total">{currency(total)}</div>
      </div>
      <div className="assets">
        {assets.map((asset) => {
          const isSaving = asset.section === "SAVINGS";
          const link = asset.biAccountId
            ? `compte/${isSaving ? "epargne" : "credit"}/${asset.id}`
            : `completer/${SectionLink[asset.section]}/${asset.id}`;
          const connection = connections?.find(
            (c) => c.id === asset.biConnectionId
          );

          return (
            <Link to={link} key={asset.id}>
              <PatrimonyAsset asset={asset} connection={connection} />
            </Link>
          );
        })}
      </div>
    </Body>
  );
};

export default PatrimonyCategory;
