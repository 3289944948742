import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import Chart from "../../components/charts/Chart";
import Loader from "../../components/Loader";
import NavPills from "../../components/NavPills";
import {
  getMyPatrimony,
  getPatrimonyHistoryByAccount,
} from "../../services/patrimony.service";
import { Transaction } from "../../types/movements";
import { Saving } from "../../types/patrimony";
import { HistoryTimeFrame } from "./history/PatrimonyHistory";
import { NavPillsContainer } from "./Patrimony";

const timeFrameOptions: Array<{
  id: HistoryTimeFrame;
  label: string;
  miniLabel?: string;
}> = [
  { id: "UP_TO_1_MONTH", label: "1 mois", miniLabel: "1 mois" },
  { id: "UP_TO_3_MONTHS", label: "3 mois", miniLabel: "3m" },
  { id: "UP_TO_6_MONTHS", label: "6 mois", miniLabel: "6m" },
  { id: "UP_TO_1_YEAR", label: "1 an", miniLabel: "1 an" },
  { id: "UP_TO_3_YEARS", label: "3 ans", miniLabel: "3a" },
  { id: "ALL", label: "Tout" },
];

const loadAllTimeFramesData = async (biAccountId?: number) => {
  if (!biAccountId) {
    return null;
  }

  const patrimonyHistory = await getPatrimonyHistoryByAccount(biAccountId);

  return patrimonyHistory;
};

type SavingChartProps = {
  transactions: Transaction[];
  biAccountId?: number;
};

const SavingChart = (props: SavingChartProps) => {
  const { biAccountId } = props;
  const [timeFrame, setTimeFrame] = useState<HistoryTimeFrame>("ALL");
  const { data: myPatrimony } = useQuery(
    ["myPatrimony"],
    () => getMyPatrimony(),
    { enabled: false }
  );

  const accountFromPatrimony = myPatrimony?.allAssets.find(
    (x) => x.id === biAccountId
  ) as Saving | undefined;

  const { data: allTimeFramesData } = useQuery(
    ["patrimonyHistoryByAccount", biAccountId],
    () => accountFromPatrimony?.history || loadAllTimeFramesData(biAccountId)
  );

  return (
    <div>
      <NavPillsContainer>
        <NavPills
          options={timeFrameOptions}
          onOptionSelected={setTimeFrame}
          selectedOptionId={timeFrame}
          widthMini="410px"
        />
      </NavPillsContainer>
      <div>
        {!allTimeFramesData || !allTimeFramesData[timeFrame] ? (
          <Loader />
        ) : (
          <Chart
            type="net"
            timeFrame={timeFrame}
            list={allTimeFramesData[timeFrame] || []}
          />
        )}
      </div>
    </div>
  );
};

export default SavingChart;
