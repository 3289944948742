import { Formik } from "formik";
import { useEffect, useState } from "react";
import HeaderBackButtonTitle from "../../../components/HeaderBackButtonTitle";
import Button from "../../../components/Button";
import PlusIcon from "../../../components/icons/Plus";
import Input from "../../../components/Input";
import InputAndSelect from "../../../components/InputAndSelect";
import Select from "../../../components/Select";
import {
  Currencies,
  OtherAssetCategories,
  OtherAssetCategory,
  OtherAssetProductsOf,
} from "../../../types/patrimony.enums";
import { Body, Content } from "./CompletePatrimony";
import { OtherAsset } from "../../../types/patrimony";
import { useNavigate, useParams } from "react-router-dom";
import {
  addOtherAssetToPatrimony,
  deleteOtherAsset,
  editOtherAsset,
  getOtherAsset,
} from "../../../services/patrimony.service";
import { queryClient } from "../../../lib/react-query";
import { useMutation, useQuery } from "@tanstack/react-query";
import Loader from "../../../components/Loader";
import { FormContainer } from "./AddSaving";
import DeleteButton from "../../../components/buttons/DeleteButton";
import {
  spacify,
  onFormattedNumericInputChange,
} from "../../../utils/format.utils";

const AddOtherAsset = () => {
  const navigate = useNavigate();

  const { id: sId } = useParams();
  const id = parseInt(sId || "");

  const { data: existingOtherAsset, isFetching } = useQuery(
    ["existingOtherAsset", id],
    () => getOtherAsset(id),
    { enabled: !!id, cacheTime: 0 }
  );

  const { mutateAsync: tryDelete, isLoading: isDeleting } = useMutation({
    mutationFn: deleteOtherAsset,
    onSuccess: () => {
      queryClient.invalidateQueries(["myPatrimony"]);
      queryClient.invalidateQueries(["existingOtherAsset", id]);
      navigate("/patrimoine");
    },
    onError: (e) => console.error(e),
  });

  const { mutateAsync: tryAdd, isLoading } = useMutation({
    mutationFn: existingOtherAsset ? editOtherAsset : addOtherAssetToPatrimony,
    onSuccess: () => {
      queryClient.invalidateQueries(["myPatrimony"]);
      queryClient.invalidateQueries(["existingOtherAsset", id]);
      navigate("/patrimoine");
    },
    onError: (e) => console.error(e),
  });

  const [formatted, setFormatted] = useState("");
  useEffect(() => {
    if (existingOtherAsset?.balance) {
      setFormatted(spacify(existingOtherAsset.balance));
    }
  }, [existingOtherAsset?.balance]);

  return (
    <Body className="complete-patrimony-body">
      <HeaderBackButtonTitle
        backTo={sId ? "/patrimoine" : "/patrimoine/completer"}
        title={
          isFetching ? (
            <Loader />
          ) : (
            `${existingOtherAsset ? "Modifier" : "Ajouter"} un autre bien`
          )
        }
      />
      <Content>
        {isFetching ? (
          <Loader />
        ) : (
          <Formik
            initialValues={
              (id && existingOtherAsset) ||
              ({
                name: "",
                category: "PROFESSIONAL",
                product: "NONE",
                balance: undefined,
                currency: "EUR",
              } as Partial<OtherAsset>)
            }
            onSubmit={(values) => {
              // Cast balance to number before submitting, it's an input type text to allow digits formatting

              if (
                typeof values.balance === "string" &&
                //@ts-ignore
                values.balance?.length > 0
              ) {
                values.balance = Number(
                  //@ts-ignore
                  values?.balance?.replace(/[^0-9.]+/g, "")
                );
              }
              if (typeof values.balance === "number") {
                values.balance = Math.round(values.balance);
              }
              tryAdd(values);
            }}
          >
            {({
              values,
              handleSubmit,
              handleChange,
              setFieldValue,
              isSubmitting,
            }) => (
              <FormContainer onSubmit={handleSubmit}>
                <div className="fields">
                  <Input
                    name="name"
                    onChange={handleChange}
                    placeholder="Nom"
                    label="Nom du bien"
                    value={values.name}
                    required
                  />
                  <Select
                    name="category"
                    optionsEnum={OtherAssetCategories}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      const category = e.target.value as OtherAssetCategory;
                      if (!OtherAssetProductsOf[category][values.product!]) {
                        setFieldValue(
                          "product",
                          Object.keys(OtherAssetProductsOf[category])[0]
                        );
                      }
                      handleChange("category")(e);
                    }}
                    label="Catégorie"
                    value={values.category}
                    required
                  />
                  <Select
                    name="product"
                    optionsEnum={
                      OtherAssetProductsOf[
                        values.category || "PROFESSIONAL"
                      ] as Record<string, string>
                    }
                    onChange={handleChange("product")}
                    label="Produit"
                    value={values.product}
                    required
                    disabled={values.category === "PROFESSIONAL"}
                  />
                  <InputAndSelect
                    type="text"
                    inputMode="numeric"
                    name="balance"
                    selectName="currency"
                    optionsEnum={Currencies}
                    onChange={(e) =>
                      onFormattedNumericInputChange(
                        e,
                        setFormatted,
                        handleChange
                      )
                    }
                    onSelectChange={handleChange("currency")}
                    placeholder="Valeur"
                    label="Valeur"
                    value={formatted}
                    selectValue={values.currency}
                    min={0}
                    required
                  />
                </div>
                <div className="buttons">
                  <Button
                    type="submit"
                    size="fullWidth"
                    icon={!existingOtherAsset ? <PlusIcon /> : undefined}
                    loading={isLoading || isSubmitting}
                  >
                    {existingOtherAsset ? "Enregistrer" : "Ajouter"}
                  </Button>

                  {existingOtherAsset && (
                    <DeleteButton
                      size="fullWidth"
                      loading={isDeleting}
                      onClick={() => tryDelete(id)}
                    />
                  )}
                </div>
              </FormContainer>
            )}
          </Formik>
        )}
      </Content>
    </Body>
  );
};

export default AddOtherAsset;
