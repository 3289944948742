import { ChartList } from "../components/charts/Chart";
import { HistoryTimeFrame } from "../pages/my-patrimony/history/PatrimonyHistory";
import { PatrimonySectionsOptions } from "../pages/my-patrimony/Patrimony";
import { assert } from "../utils/assert";
import { sum } from "../utils/math.utils";
import { ObjectEntries, ObjectKeys } from "../utils/utils";
import { Investment, Transaction } from "./movements";
import {
  RealEstateProduct,
  RealEstateCategory,
  Currency,
  PatrimonySection,
  OtherAssetCategory,
  OtherAssetProduct,
  CreditCategory,
  CreditType,
  SavingProduct,
  SavingCategory,
  PatrimonyCategory,
  SavingLifeInsuranceProduct,
  SavingProducts,
} from "./patrimony.enums";

interface PatrimonyElt<C> {
  id: number;
  userId: number;
  name: string;
  category: C;
  currency: Currency;
  balance: number;
}

type RiskedFields = { annualYield: number; riskLevel: RiskLevel };

type PatrimonyGood<C, P> = PatrimonyElt<C> & RiskedFields & { product: P };

type PatrimonyBankAccount = {
  bankName: string;
  biAccountId?: number;
  biConnectionId?: number;
  biUpdatedAt?: string;
};

export type RealEstate = PatrimonyGood<
  RealEstateCategory,
  RealEstateProduct
> & {
  ownershipPercentage: number;
  livingArea: number;
  address: string;
  city: string;
  zipCode: string;
  cellarArea: number;
  parkingArea: number;
  terraceArea: number;
  gardenArea: number;
};

export type OtherAsset = PatrimonyGood<OtherAssetCategory, OtherAssetProduct>;

type CreditFields = {
  maturityDate: string;
  remainingMaturities: number;
  interestRate: number;
  monthlyPayment: number;
  type: CreditType;
};

export type Credit = PatrimonyElt<CreditCategory> &
  PatrimonyBankAccount &
  CreditFields;

export interface Saving
  extends PatrimonyGood<SavingCategory, SavingProduct>,
    PatrimonyBankAccount {
  history?: Record<HistoryTimeFrame, ChartList[]>;
  transactions?: Transaction[];
  investments?: Investment[];
}

export interface PatrimonyAsset
  extends Pick<PatrimonyElt<never>, "id" | "name" | "currency">,
    Omit<PatrimonyBankAccount, "bankName">,
    Partial<RiskedFields>,
    Partial<CreditFields> {
  section: PatrimonySection;
  eurValue: number;
  value: number;
  userId: number;
  category: PatrimonyCategory;
  balance: number;

  biConnectorUuid?: string;

  investments?: Investment[];
  history?: Record<HistoryTimeFrame, ChartList[]>;
  transactions?: Transaction[];
  bankName?: string;
}

export const areAssetsSame = <
  T extends Pick<PatrimonyAsset, "id" | "section"> &
    Partial<Pick<Investment, "savingId">>
>(
  a1: T,
  a2: T
) =>
  a1.section === a2.section && a1.id === a2.id && a1.savingId === a2.savingId;

export type ChangedAsset<T extends "editing" | undefined = undefined> = Pick<
  PatrimonyAsset,
  "id" | "section" | "riskLevel"
> & {
  annualYield?: T extends "editing" ? number | "" : number;
} & Partial<Pick<Investment, "savingId">>;

export const assetsFrom = <
  T extends Omit<PatrimonyAsset, "annualYield">
>(categories: {
  [category in PatrimonyCategory]?: { assets: T[] };
}) => {
  return Object.values(categories)
    .map(({ assets }) => assets)
    .flat();
};

export type IncomeType = "net" | "brut";

export const RiskLevels = {
  LOW: "Faible",
  MEDIUM: "Moyen",
  HIGH: "Élevé",
};
export type RiskLevel = keyof typeof RiskLevels;
export const RiskLevelsWeights = { LOW: 1, MEDIUM: 2, HIGH: 3 } as const;
export const RiskLevelsWeightsSteps = {
  LOW: 1,
  MEDIUM: 1.66,
  HIGH: 2.33,
} as const;
export const RiskLevelsProfiles = {
  LOW: "PRUDENT",
  MEDIUM: "STANDARD",
  HIGH: "AGRESSIF",
} as const;

export interface MyPatrimony {
  totalNet: number;
  totalGross: number;
  sections: {
    [section in PatrimonySection]: {
      total: number;
      categories: {
        [category in PatrimonyCategory]?: {
          total: number;
          assets: PatrimonyAsset[];
        };
      };
    };
  };
}

type RiskedPatrimonySection = Exclude<PatrimonySection, "CREDITS">;
type RiskedPatrimonyCategory = PatrimonyCategory | SavingLifeInsuranceProduct;
export type RiskedPatrimonyAsset = Omit<PatrimonyAsset, "annualYield"> & {
  riskLevel: RiskLevel;
  annualYield: number | "";
  annualProduct: number;
} & Pick<Investment, "savingId" | "product">;

export type MyRiskedPatrimony = {
  annualYieldAv: number;
  annualProductTotal: number;
  riskLevelWeightedAv: number;
  highRiskedAssetsShare: number;
  sections: {
    [section in RiskedPatrimonySection]: {
      total: number;
      annualYieldAv: number;
      annualProductTotal: number;
      riskLevelWeightedAv: number;
      categories: {
        [category in RiskedPatrimonyCategory]?: {
          total: number;
          annualYieldAv: number;
          annualProductTotal: number;
          riskLevelWeightedAv: number;
          assets: RiskedPatrimonyAsset[];
        };
      };
    };
  };
};

type RiskedCategories =
  MyRiskedPatrimony["sections"][RiskedPatrimonySection]["categories"];

export function getTitle(section: RiskedPatrimonySection) {
  return PatrimonySectionsOptions.find((x) => x.id === section)!.label;
}

function getRiskLevelAv(riskLevelWeightedAv: number): RiskLevel {
  // assert(
  //   riskLevelWeightedAv < 1 || 3 < riskLevelWeightedAv,
  //   `riskLevelWeightedAv should be between 1 and 3. Value : ${riskLevelWeightedAv}`
  // );
  if (riskLevelWeightedAv < 1) return "LOW";
  if (riskLevelWeightedAv > 3) return "HIGH";
  return ObjectEntries(RiskLevelsWeightsSteps)
    .reverse()
    .find(([, v]) => v <= riskLevelWeightedAv)![0];
}

export function getRiskLevelAvLabel(riskLevelWeightedAv: number) {
  return RiskLevels[getRiskLevelAv(riskLevelWeightedAv)];
}

export function getRiskLevelProfile(riskLevelWeightedAv: number) {
  return RiskLevelsProfiles[getRiskLevelAv(riskLevelWeightedAv)];
}

function getRiskLevelWeightedAv<
  T extends { riskLevel: RiskLevel; eurValue: number }
>(assets: T[]) {
  return (
    sum(
      assets.map((x) => RiskLevelsWeights[x.riskLevel] * Math.abs(x.eurValue))
    ) / (sum(assets.map((x) => Math.abs(x.eurValue))) || 1)
  );
}

export function getRiskedAssets(
  category: PatrimonyCategory,
  c: MyPatrimony["sections"][PatrimonySection]["categories"][PatrimonyCategory],
  section: PatrimonySection
) {
  return category !== "LIFE_INSURANCE"
    ? c!.assets
    : c!.assets
        .map((x) =>
          x.investments!.map((i) => ({
            ...i,
            section,
            eurValue: i.eurValue!,
            name: x.name,
          }))
        )
        .flat();
}

export function formatMyRiskedPatrimony(
  myPatrimony: MyPatrimony,
  changedAssets: ChangedAsset<"editing">[] = []
): MyRiskedPatrimony {
  const sections = ObjectEntries(myPatrimony.sections)
    .filter(([s]) => s !== "CREDITS")
    .reduce<MyRiskedPatrimony["sections"]>(
      (acc, [section, { categories: rawCategories, total }]) => {
        const categories = ObjectEntries(
          rawCategories
        ).reduce<RiskedCategories>((acc, [category, c]) => {
          const assetsToMap = getRiskedAssets(category, c, section);
          const assets = assetsToMap.map((asset) => {
            const changedAsset = changedAssets.find((x) =>
              areAssetsSame(x, asset)
            );
            const riskLevel = changedAsset?.riskLevel || asset.riskLevel!;
            const annualYield = changedAsset?.annualYield ?? asset.annualYield!;
            const annualProduct = (asset.annualYield || 0) * asset.eurValue!;
            return {
              ...asset,
              annualYield,
              annualProduct,
              riskLevel,
            };
          });

          const annualProductTotal = sum(assets.map((x) => x.annualProduct));
          const annualYieldAv = annualProductTotal / (c!.total || 1);
          const riskLevelWeightedAv = getRiskLevelWeightedAv(assets);

          let maybeLifeInsuranceCategories: RiskedCategories = {};

          if (category === "LIFE_INSURANCE") {
            maybeLifeInsuranceCategories = ObjectKeys(
              SavingProducts.LIFE_INSURANCE
            ).reduce((acc, product) => {
              const productAssets = assets.filter(
                (x) =>
                  (x as { product: SavingLifeInsuranceProduct }).product ===
                  product
              );
              if (!productAssets.length) return acc;

              const annualProductTotal = sum(
                productAssets.map((x) => x.annualProduct)
              );
              const total = sum(productAssets.map((x) => x.eurValue));
              const annualYieldAv = annualProductTotal / (total || 1);
              const riskLevelWeightedAv = getRiskLevelWeightedAv(assets);

              return {
                ...acc,
                [product]: {
                  total,
                  annualYieldAv,
                  annualProductTotal,
                  riskLevelWeightedAv,
                  assets: productAssets,
                },
              };
            }, {});
            maybeLifeInsuranceCategories = ObjectEntries(
              maybeLifeInsuranceCategories
            )
              .sort(([, a], [, b]) => b!.total - a!.total)
              .reduce((acc, [x, y]) => ({ ...acc, [x]: y }), {});
          }

          return {
            ...acc,
            [category]: {
              total: c!.total,
              annualYieldAv,
              annualProductTotal,
              riskLevelWeightedAv,
              assets: category !== "LIFE_INSURANCE" ? assets : [],
            },
            ...maybeLifeInsuranceCategories,
          };
        }, {});

        const { annualProductTotal, riskLevelWeightedTotal, absTotal } =
          Object.values(categories).reduce(
            (acc, x) => {
              acc.annualProductTotal += x.annualProductTotal;
              acc.riskLevelWeightedTotal +=
                x.riskLevelWeightedAv * Math.abs(x.total);
              acc.absTotal += Math.abs(x.total);
              return acc;
            },
            { annualProductTotal: 0, riskLevelWeightedTotal: 0, absTotal: 0 }
          );

        const annualYieldAv = annualProductTotal / (total || 1);
        const riskLevelWeightedAv =
          (riskLevelWeightedTotal || 1) / (absTotal || 1);

        return {
          ...acc,
          [section as RiskedPatrimonySection]: {
            total,
            annualYieldAv,
            annualProductTotal,
            riskLevelWeightedAv,
            categories,
          },
        };
      },
      {} as MyRiskedPatrimony["sections"]
    );

  const { annualProductTotal, riskLevelWeightedTotal, absTotal } =
    Object.values(sections).reduce(
      (acc, x) => {
        acc.annualProductTotal += x.annualProductTotal;
        acc.riskLevelWeightedTotal += x.riskLevelWeightedAv * Math.abs(x.total);
        acc.absTotal += Math.abs(x.total);
        return acc;
      },
      { annualProductTotal: 0, riskLevelWeightedTotal: 0, absTotal: 0 }
    );

  const annualYieldAv = annualProductTotal / (myPatrimony.totalGross || 1);
  const riskLevelWeightedAv = (riskLevelWeightedTotal || 1) / (absTotal || 1);

  const getHighRiskedAssets = Object.values(sections).map((x) =>
    assetsFrom(x.categories)
      .filter((a) => a.riskLevel === "HIGH")
      .map((a) => a.eurValue)
  );
  const highRiskedAssetsShare =
    sum(getHighRiskedAssets.flat()) / (myPatrimony.totalGross || 1);
  return {
    sections,
    annualYieldAv,
    annualProductTotal,
    riskLevelWeightedAv,
    highRiskedAssetsShare,
  };
}

export interface HistoryTotals {
  date: string;
  savings: number;
  realEstate: number;
  credits: number;
  otherAssets: number;
}

export type History = Record<HistoryTimeFrame, ChartList[]>;

export function formatUpdatedInvestments(s: Partial<Saving>) {
  if (s.investments) {
    if (!s.biAccountId) {
      if (s.category === "LIFE_INSURANCE") {
        s.balance = sum(s.investments?.map((i) => i.value || 0) || []);
      } else {
        s.investments = [];
      }
    } else {
      delete s.investments;
    }
  }
  return s;
}

export function formatInitialSaving(existingSaving: Saving | undefined) {
  if (!existingSaving) return undefined;
  const { transactions, ...saving } = existingSaving;

  return saving;
}
