import { useState } from "react";
import styled from "styled-components";
import { BulletText } from "../BulletText";
import { percentage } from "../../utils/format.utils";
import { sum } from "../../utils/math.utils";

const Labels = styled.div`
  margin-top: 0.5rem;
  > div {
    transition: 0.2s;
    text-align: start;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 16px;
`;

const Circle = styled.circle`
  transition: 0.2s;
  transform: scale(0.9, 0.9);
  &:hover {
    transform: scale(1, 1);
  }
`;

type LabelProps = {
  x: DataElt;
  total: number;
  hoveredShareId: string | undefined;
  isNormal?: true;
};
const Label = ({ x, total, hoveredShareId, isNormal }: LabelProps) => (
  <BulletText
    key={x.id}
    color={x.color}
    children={`${x.label} - ${percentage(x.value / total, 1)}`}
    style={
      !isNormal
        ? {
            position: "absolute",
            bottom: 0,
            WebkitTextStrokeWidth: 0.5,
            color: hoveredShareId === x.id ? "unset" : "transparent",
          }
        : {}
    }
  />
);

type DataElt = { id: string; label: string; value: number; color: string };

const viewBoxSide = 200;
const r = viewBoxSide / 2;
const PI_R = Math.PI * r;

export type AsterChartProps = {
  data: DataElt[];
};
const AsterChart = ({ data: rawData }: AsterChartProps) => {
  const data = rawData.filter((x) => x.value > 0);
  const total = sum(data.map((x) => x.value));

  const [hoveredShareId, setHoveredShareId] = useState<string>();
  const side = Math.min(
    (3 / 4) * window.innerWidth,
    (1 / 3) * window.innerHeight
  );

  return (
    <>
      <svg
        viewBox={`0 0 ${viewBoxSide} ${viewBoxSide}`}
        xmlns="http://www.w3.org/2000/svg"
        width={side}
        height={side}
      >
        <g transform={`translate(${r}, ${r}) rotate(-90)`}>
          {data.map(
            function (this: { acc: number }, x) {
              const prevAcc = this.acc;
              const share = x.value / total;
              this.acc += 360 * share;
              return (
                <g key={x.id} transform={`rotate(${prevAcc})`}>
                  <Circle
                    r={r / 2}
                    fill="none"
                    stroke={x.color}
                    strokeWidth={r}
                    strokeDasharray={`${share * PI_R} ${PI_R}`}
                    onMouseEnter={() => setHoveredShareId(x.id)}
                    onMouseLeave={() =>
                      setHoveredShareId((prev) =>
                        prev === x.id ? undefined : prev
                      )
                    }
                  />
                </g>
              );
            },
            { acc: 0 }
          )}
          <circle r={0.2 * r} fill="white" />
        </g>
      </svg>
      <Labels>
        {data.map((x) => {
          const params = { x, hoveredShareId, total };
          return (
            <div key={x.id} style={{ position: "relative" }}>
              <Label {...params} isNormal />
              <Label {...params} />
            </div>
          );
        })}
      </Labels>
    </>
  );
};

export default AsterChart;
