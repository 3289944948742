import { useEffect, useState } from "react";
import styled from "styled-components";
import Chart from "../../../components/charts/Chart";
import Loader from "../../../components/Loader";
import NavPills from "../../../components/NavPills";
import { getPatrimonyHistory } from "../../../services/patrimony.service";
import { History, IncomeType } from "../../../types/patrimony";
import { NavPillsContainer } from "../Patrimony";

const ChartContainer = styled.div`
  overflow: hidden;
  @media (max-width: 900px) {
    padding: 0 16px;
  }
`;

export type HistoryTimeFrame =
  | "ALL"
  | "UP_TO_3_YEARS"
  | "UP_TO_1_YEAR"
  | "UP_TO_6_MONTHS"
  | "UP_TO_3_MONTHS"
  | "UP_TO_1_MONTH";

const timeFrameOptions: Array<{
  id: HistoryTimeFrame;
  label: string;
  miniLabel?: string;
}> = [
  { id: "UP_TO_1_MONTH", label: "1 mois", miniLabel: "1 mois" },
  { id: "UP_TO_3_MONTHS", label: "3 mois", miniLabel: "3m" },
  { id: "UP_TO_6_MONTHS", label: "6 mois", miniLabel: "6m" },
  { id: "UP_TO_1_YEAR", label: "1 an", miniLabel: "1 an" },
  { id: "UP_TO_3_YEARS", label: "3 ans", miniLabel: "3a" },
  { id: "ALL", label: "Tout" },
];

type PatrimonyHistoryProps = {
  type: IncomeType | undefined;
};
const PatrimonyHistory = ({ type }: PatrimonyHistoryProps) => {
  const [timeFrame, setTimeFrame] = useState<HistoryTimeFrame>("UP_TO_1_MONTH");
  const [allTimeFramesData, setAllTimeFramesData] = useState<History>();

  const loadAllTimeFramesData = async () => {

    const data = await getPatrimonyHistory();
    setAllTimeFramesData(data);
  };

  useEffect(() => {
    loadAllTimeFramesData();
  }, []);

  if (!type) {
    return null;
  }

  return (
    <>
      <NavPillsContainer>
        <NavPills
          options={timeFrameOptions}
          onOptionSelected={setTimeFrame}
          selectedOptionId={timeFrame}
          widthMini="410px"
        />
      </NavPillsContainer>
      <ChartContainer>
        {!allTimeFramesData || !allTimeFramesData[timeFrame] ? (
          <Loader />
        ) : (
          <Chart
            list={allTimeFramesData[timeFrame] || []}
            type={type}
            timeFrame={timeFrame}
          />
        )}
      </ChartContainer>
    </>
  );
};

export default PatrimonyHistory;
