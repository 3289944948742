import { get, httpDelete, post } from "../lib/axios";
import { Connection } from "../types/connection";
import {
  AccountType,
  Investment,
  Transaction,
  UpdateInvestmentDto,
  UpdateTransactionDto,
} from "../types/movements";
import { getJwt } from "../utils/storage.utils";
import {
  ChangedAsset,
  Credit,
  History,
  MyPatrimony,
  OtherAsset,
  RealEstate,
  Saving,
} from "./../types/patrimony";

export const getMyPatrimony = async () => {
  const myPatrimony = await get<MyPatrimony>("/patrimony/getMyPatrimony");
  const allAssets = Object.values(myPatrimony.sections).flatMap((x) =>
    Object.values(x.categories).flatMap((y) => y.assets)
  );
  return {
    ...myPatrimony,
    allAssets: allAssets,
  };
};

export const syncBiAccounts = () => {
  return post<Connection[]>("/patrimony/syncBiAccounts");
};

export const addRealEstateToPatrimony = (data: Partial<RealEstate>) => {
  return post<RealEstate>("/patrimony/addRealEstateToPatrimony", data);
};

export const getRealEstate = (id: number) => {
  return get<RealEstate>("/patrimony/getRealEstate", { id });
};

export const editRealEstate = (data: Partial<RealEstate>) => {
  return post<RealEstate>("/patrimony/editRealEstate", data);
};

export const deleteRealEstate = (id: number) => {
  return httpDelete<boolean>("/patrimony/deleteRealEstate", { id });
};

export const addOtherAssetToPatrimony = (data: Partial<OtherAsset>) => {
  return post<OtherAsset>("/patrimony/addOtherAssetToPatrimony", data);
};

export const getOtherAsset = (id: number) => {
  return get<OtherAsset>("/patrimony/getOtherAsset", { id });
};

export const editOtherAsset = (data: Partial<OtherAsset>) => {
  return post<OtherAsset>("/patrimony/editOtherAsset", data);
};

export const deleteOtherAsset = (id: number) => {
  return httpDelete<boolean>("/patrimony/deleteOtherAsset", { id });
};

export const addCreditToPatrimony = (data: Partial<Credit>) => {
  return post<Credit>("/patrimony/addCreditToPatrimony", data);
};

export const getCredit = (id: number) => {
  return get<Credit>("/patrimony/getCredit", { id });
};

export const editCredit = (data: Partial<Credit>) => {
  return post<Credit>("/patrimony/editCredit", data);
};

export const deleteCredit = (id: number) => {
  return httpDelete<boolean>("/patrimony/deleteCredit", { id });
};

export const addSavingToPatrimony = (data: Partial<Saving>) => {
  return post<Saving>("/patrimony/addSavingToPatrimony", data);
};

export const getSaving = (id: number) => {
  return get<Saving>("/patrimony/getSaving", { id });
};

export const editSaving = (data: Partial<Saving>) => {
  return post<Saving>("/patrimony/editSaving", data);
};

export const deleteSaving = (id: number) => {
  return httpDelete<boolean>("/patrimony/deleteSaving", { id });
};

export const getAccount = (id: number, type: AccountType) => {
  return get<Saving | Credit>("/patrimony/getAccount", {
    id,
    type,
  });
};

export const getConnection = (id: number) => {
  return get<Connection>("/patrimony/getConnection", { id });
};

export const getConnections = () => {
  return get<Connection[]>("/patrimony/getConnections");
};

export const getPatrimonyHistory = () => {
  return get<History>("/patrimony/getHistoryTotals");
};

export const getPatrimonyHistoryByAccount = (biAccountId?: number) => {
  if (!biAccountId) return null;
  return get<History>(`/patrimony/getHistory/${biAccountId}`);
};

export const editChangedAsset = async (
  changedAsset: ChangedAsset
): Promise<ChangedAsset> => {
  const { section, savingId, ...assetToUpdate } = changedAsset;
  let editedAsset: Omit<ChangedAsset, "section">;

  if (section === "SAVINGS") {
    if (savingId) {
      editedAsset = await updateInvestment(assetToUpdate);
    } else {
      editedAsset = await editSaving(assetToUpdate);
    }
  } else if (section === "REAL_ESTATE") {
    editedAsset = await editRealEstate(assetToUpdate);
  } else if (section === "OTHERS") {
    editedAsset = await editOtherAsset(assetToUpdate);
  } else {
    throw new Error(`Cannot edit asset ${changedAsset}`);
  }

  return { section, ...editedAsset };
};

export const updateTransaction = (data: UpdateTransactionDto) => {
  return post<Transaction>("/patrimony/updateTransaction", data);
};

export const updateInvestment = (data: UpdateInvestmentDto) => {
  return post<Investment>("/patrimony/updateInvestment", data);
};

export const exportPatrimony = () => {
  return post(
    "/patrimony/exportPatrimony",
    { jwt: getJwt() },
    { responseType: "blob" }
  );
};
