import { useEffect, useState } from "react";
import { Formik } from "formik";
import Button from "../../../components/Button";
import HeaderBackButtonTitle from "../../../components/HeaderBackButtonTitle";
import PlusIcon from "../../../components/icons/Plus";
import Input from "../../../components/Input";
import InputAndSelect from "../../../components/InputAndSelect";
import Select from "../../../components/Select";
import {
  CreditCategories,
  CreditTypes,
  Currencies,
  TimeUnit,
  TimeUnits,
} from "../../../types/patrimony.enums";
import { Body, Content } from "./CompletePatrimony";
import { Credit } from "../../../types/patrimony";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  addCreditToPatrimony,
  deleteCredit,
  editCredit,
  getCredit,
} from "../../../services/patrimony.service";
import { queryClient } from "../../../lib/react-query";
import { useMutation, useQuery } from "@tanstack/react-query";
import Loader from "../../../components/Loader";
import { format } from "date-fns";
import { FormContainer } from "./AddSaving";
import DeleteButton from "../../../components/buttons/DeleteButton";
import {
  round,
  roundPercentage,
  spacify,
  onFormattedNumericInputChange,
} from "../../../utils/format.utils";
import { monthsSince } from "../../../utils/date.utils";
import { calcRemainingMaturities } from "../../analysis-tools/utils/calcRemainingMaturities";

type CreditFormFieldsProps = {
  id?: number;
  existingCredit?: Credit;
  disabledForm?: boolean;
  onSuccessBackLink?: string;
};
export const CreditForm = ({
  id,
  existingCredit,
  disabledForm,
  onSuccessBackLink,
}: CreditFormFieldsProps) => {
  const navigate = useNavigate();
  const [timeUnit, setTimeUnit] = useState<TimeUnit>("MONTH");

  const { mutateAsync: tryDelete, isLoading: isDeleting } = useMutation({
    mutationFn: deleteCredit,
    onSuccess: () => {
      queryClient.invalidateQueries(["myPatrimony"]);
      queryClient.invalidateQueries(["existingCredit", id]);
      navigate("/patrimoine");
    },
    onError: (e) => console.error(e),
  });

  const { mutateAsync: tryAdd, isLoading } = useMutation({
    mutationFn: existingCredit ? editCredit : addCreditToPatrimony,
    onSuccess: () => {
      queryClient.invalidateQueries(["myPatrimony"]);
      queryClient.invalidateQueries(["existingCredit", id]);
      navigate(onSuccessBackLink ? onSuccessBackLink : "/patrimoine");
    },
    onError: (e) => console.error(e),
  });
  const [formatted, setFormatted] = useState(
    existingCredit?.balance ? spacify(existingCredit.balance) : ""
  );

  return (
    <Formik
      initialValues={
        existingCredit
          ? {
              ...existingCredit,
              maturityDate: existingCredit.maturityDate
                ? format(new Date(existingCredit.maturityDate), "yyyy-MM-dd")
                : "",
              // Sometimes, the `remainingMaturities` isnt defined.
              // In this case we just calculate it from today and the `maturityDate`
              remainingMaturities:
                typeof existingCredit.remainingMaturities === "undefined" ||
                existingCredit.remainingMaturities === null
                  ? calcRemainingMaturities(existingCredit.maturityDate)
                  : existingCredit.remainingMaturities,
            }
          : ({
              name: "",
              category: "REAL_ESTATE_CREDIT",
              balance: undefined,
              currency: "EUR",
              bankName: "",
              maturityDate: "",
              remainingMaturities: undefined,
              interestRate: undefined,
              monthlyPayment: undefined,
              type: "AMORTIZATION",
            } as Partial<Credit>)
      }
      onSubmit={(values) => {
        // Cast balance to number before submitting, it's an input type text to allow digits formatting
        // @ts-ignore
        if (typeof values.balance === "string" && values.balance.length > 0) {
          // @ts-ignore
          values.balance = Number(values?.balance?.replace(/[^0-9.]+/g, ""));
        } else if (typeof values.balance === "number") {
          values.balance = Math.round(values.balance);
        }

        tryAdd(values as any);
      }}
    >
      {({
        values,
        handleSubmit,
        handleChange,
        setFieldValue,
        isSubmitting,
      }) => (
        <FormContainer onSubmit={handleSubmit}>
          <div className="fields">
            <Input
              name="name"
              onChange={handleChange}
              placeholder="Nom"
              label="Nom du bien"
              value={values.name}
              required
              disabled={disabledForm}
            />
            <Select
              name="category"
              optionsEnum={CreditCategories}
              onChange={handleChange("category")}
              label="Catégorie"
              value={values.category}
              required
              disabled={disabledForm}
            />
            <InputAndSelect
              type="text"
              inputMode="numeric"
              name="balance"
              selectName="currency"
              optionsEnum={Currencies}
              onChange={(e) =>
                onFormattedNumericInputChange(e, setFormatted, handleChange)
              }
              onSelectChange={handleChange("currency")}
              placeholder="Montant restant dû"
              label="Montant restant dû"
              value={formatted}
              selectValue={values.currency}
              min={0}
              required
              disabled={disabledForm || !!existingCredit?.biAccountId}
            />
            <Input
              name="bankName"
              onChange={handleChange}
              placeholder="Étalissement bancaire"
              label="Étalissement bancaire"
              value={values.bankName}
              required
              disabled={disabledForm}
            />
            <Input
              type="date"
              name="maturityDate"
              onChange={(e) => {
                const newValue = e.target.value;
                const remainingMaturities = monthsSince(newValue);
                if (timeUnit === "YEAR" && remainingMaturities % 12) {
                  setTimeUnit("MONTH");
                }
                setFieldValue("remainingMaturities", remainingMaturities);
                setFieldValue("maturityDate", newValue);
              }}
              placeholder="Date de fin"
              label="Date de fin"
              value={values.maturityDate}
              required
              disabled={disabledForm}
            />
            <InputAndSelect
              type="number"
              name="remainingMaturities"
              optionsEnum={TimeUnits}
              selectValue={timeUnit}
              onChange={(e) => {
                const value = e.target.value;
                setFieldValue(
                  "remainingMaturities",
                  timeUnit === "MONTH" ? +value : +value * 12
                );
              }}
              onSelectChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                const unit = e.target.value as TimeUnit;
                setTimeUnit(unit);
                if (values.remainingMaturities) {
                  if (unit === "MONTH") {
                    setFieldValue(
                      "remainingMaturities",
                      values.remainingMaturities ?? 0 / 12
                    );
                  } else if (unit === "YEAR") {
                    setFieldValue(
                      "remainingMaturities",
                      values.remainingMaturities ?? 0 * 12
                    );
                  }
                }
              }}
              placeholder="Durée résiduelle"
              label="Durée résiduelle"
              paddingRight="100px"
              value={
                (timeUnit === "MONTH"
                  ? values.remainingMaturities
                  : (values.remainingMaturities || 0) / 12) || ""
              }
              min={0}
              required
              disabled={disabledForm}
            />
            <Input
              type="number"
              unit="%"
              name="interestRate"
              onChange={(e) => {
                setFieldValue(
                  "interestRate",
                  roundPercentage(parseFloat(e.target.value) / 100)
                );
              }}
              placeholder="Taux hors assurance"
              label="Taux hors assurance"
              value={round((values.interestRate || 0) * 100) || ""}
              step={0.01}
              required
              disabled={disabledForm}
            />
            <InputAndSelect
              type="number"
              name="monthlyPayment"
              selectName="currency"
              optionsEnum={Currencies}
              onChange={handleChange}
              onSelectChange={handleChange("currency")}
              placeholder="Mensualité"
              label="Mensualité"
              value={values.monthlyPayment || ""}
              selectValue={values.currency}
              required
              disabled={disabledForm}
            />
            <Select
              name="type"
              optionsEnum={CreditTypes}
              onChange={handleChange("type")}
              label="Type de crédit"
              value={values.type}
              required
              disabled={disabledForm}
            />
          </div>
          {!disabledForm && (
            <div className="buttons">
              <Button
                type="submit"
                size="fullWidth"
                icon={!existingCredit ? <PlusIcon /> : undefined}
                loading={isLoading || isSubmitting}
              >
                {existingCredit ? "Enregistrer" : "Ajouter"}
              </Button>

              {existingCredit && (
                <DeleteButton
                  size="fullWidth"
                  loading={isDeleting}
                  onClick={() => id && tryDelete(id)}
                />
              )}
            </div>
          )}
        </FormContainer>
      )}
    </Formik>
  );
};

const AddCredit = () => {
  const { id: sId } = useParams();
  const id = parseInt(sId || "");

  const [searchParams] = useSearchParams();
  const fromFuturePatrimony = searchParams.get("f") === "";

  const { data: existingCredit, isFetching } = useQuery(
    ["existingCredit", id],
    async () => {
      const rep = await getCredit(id);

      // Sometimes, the `remainingMaturities` isnt defined.
      // In this case we just calculate it from today and the `maturityDate`
      if (
        "maturityDate" in rep &&
        (typeof rep?.remainingMaturities === "undefined" ||
          rep?.remainingMaturities === null) &&
        rep.maturityDate
      ) {
        rep.remainingMaturities = calcRemainingMaturities(rep.maturityDate);
      }

      return rep;
    },
    { enabled: !!id, cacheTime: 0 }
  );

  const backLink = fromFuturePatrimony
    ? `/outils-analyse/patrimoine-futur#credit_${id}`
    : !sId
    ? "/patrimoine/completer/sync-credit"
    : existingCredit?.biAccountId
    ? `/patrimoine/compte/credit/${existingCredit.id}`
    : "/patrimoine";

  return (
    <Body className="complete-patrimony-body">
      <HeaderBackButtonTitle
        backTo={backLink}
        title={
          isFetching ? (
            <Loader />
          ) : (
            `${existingCredit ? "Modifier" : "Ajouter"} un crédit`
          )
        }
      />
      <Content>
        {isFetching ? (
          <Loader />
        ) : (
          <CreditForm
            id={id}
            existingCredit={existingCredit}
            onSuccessBackLink={fromFuturePatrimony ? backLink : undefined}
          />
        )}
      </Content>
    </Body>
  );
};

export default AddCredit;
