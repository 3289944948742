import styled from "styled-components";
import {
  Content,
  Body as PatrimonyBody,
  Header as PatrimonyHeader,
} from "../my-patrimony/Patrimony";
import Link from "../../components/Link";
import BackButton from "../../components/buttons/BackButton";
import {
  cancelMeeting,
  getMeeting,
  sendEmailMeeting,
} from "../../services/meeting.service";
import { useMutation, useQuery } from "@tanstack/react-query";
import Loader from "../../components/Loader";
import MeetingCard from "./MeetingCard";
import PDFIcon from "../../components/icons/PDF";
import CancelRoundIcon from "../../components/icons/CancelRound";
import ToggleSwitch from "../../components/buttons/ToggleSwitch";
import { useState } from "react";
import ConfirmButton from "../../components/buttons/DeleteButton";
import { queryClient } from "../../lib/react-query";
import { useNavigate } from "react-router-dom";
import { exportBudget } from "../../services/budget.service";
import { exportPatrimony } from "../../services/patrimony.service";
import { openPDF } from "../../utils/export.utils";
import Button from "../../components/Button";

const Body = styled(PatrimonyBody)`
  .meeting-information {
    margin: 2rem;
    background-color: var(--white);
  }

  .disabled {
    pointer-events: none !important;
    cursor: default !important;
  }

  .share-documents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    .share {
      .show-pdf {
        cursor: pointer;
        display: flex;
        gap: 1rem;
        align-items: center;
      }
      display: flex;
      gap: 1rem;
      align-items: center;
      margin-top: 1rem;
    }
    @media (max-width: 900px) {
      align-items: stretch;
      .react-switch {
        margin-left: auto;
      }
    }
  }

  .button-group {
    margin-left: auto;
    display: flex;
    gap: 1rem;
    Button {
      radius: 8px;
      padding: 8px;
    }

    @media (max-width: 900px) {
      margin-left: 0;
      justify-content: center;
    }

    .cancel-button {
      border: 1px solid var(--error-500);
      color: var(--error-500);
    }
  }

  .sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    bottom: 1rem;
    align-self: center;
  }
`;

const Header = styled(PatrimonyHeader)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  @media (max-width: 900px) {
    color: var(--white);
    h1 {
      margin-right: auto;
      margin-left: auto;
    }
  }
`;

const StyledBox = styled.div`
   {
    background-color: var(--white);
    border: px solid "var(--gray-200)";
    border-radius: 8px;
    padding: 1rem;
    margin: 2rem;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    align-items: center;
    height: fit-content;
  }
`;

const MyMeeting = () => {
  const navigate = useNavigate();
  const id = Number(window.location.pathname.split("/").pop());
  const [sendBudget, setSendBudget] = useState(false);
  const [sendPatrimony, setSendPatrimony] = useState(false);
  const [loadingOpenBudget, setLoadingOpenBudget] = useState(false);
  const [loadingOpenPatrimony, setLoadingOpenPatrimony] = useState(false);

  const { mutateAsync: tryCancelMeeting, isLoading: isCanceling } = useMutation(
    {
      mutationFn: cancelMeeting,
      onSuccess: () => {
        queryClient.invalidateQueries(["meetings"]);
        navigate("/mon-conseiller");
      },
      onError: (e) => console.error(e),
    }
  );

  const { data: myMeeting, isLoading: isLoadingMeeting } = useQuery(
    ["meetings", id],
    () => getMeeting(id)
  );

  if (isLoadingMeeting) {
    return <Loader />;
  }
  function sendEmail(): void {
    sendEmailMeeting(sendBudget, sendPatrimony, myMeeting!);
    navigate("/mon-conseiller");
  }

  async function handleOpenPDF(type: string): Promise<void> {
    let pdfData;
    if (type === "patrimony") {
      setLoadingOpenPatrimony(true);
      pdfData = await exportPatrimony();
      setLoadingOpenPatrimony(false);
    } else {
      setLoadingOpenBudget(true);
      const month = new Date().toISOString().split("T")[0].slice(0, 7);
      pdfData = await exportBudget(month);
      setLoadingOpenBudget(false);
    }
    openPDF(pdfData);
  }

  return (
    <Body>
      <Header>
        <Link to="/mon-conseiller">
          <BackButton />
        </Link>
        <h1>Information du rendez-vous</h1>
        <div className="button-group desktop-only">
          {/* <Button>
            <CalendarPlusIcon /> Ajouter à mon agenda
          </Button> */}
          {/* <Button variant="white">
            <ClockFastForwardIcon /> Déplacer
          </Button> */}
          <ConfirmButton
            className="cancel-button"
            label="Annuler"
            icon={<CancelRoundIcon />}
            onClick={() =>
              tryCancelMeeting({
                id_meeting: myMeeting!.id_meeting,
                idAdvisor: myMeeting!.advisorId,
              })
            }
            loading={isCanceling}
          ></ConfirmButton>
        </div>
      </Header>
      <Content>
        <div className="meeting-information">
          <MeetingCard
            disabled={true}
            meeting={myMeeting!}
            withBiography={true}
            withExpertise={true}
          />
        </div>

        <StyledBox className="share-documents">
          <p>
            Partager mon bilan patrimonial Arpagon avec{" "}
            <b>
              {myMeeting?.advisor.firstname} {myMeeting?.advisor.lastname}
            </b>{" "}
            pour bénéficier de conseils personnalisés lors de mon RDV
          </p>
          <div className="share">
            <div
              className="show-pdf"
              onClick={() => handleOpenPDF("patrimony")}
            >
              {loadingOpenPatrimony && <Loader />}
              {!loadingOpenPatrimony && <PDFIcon />}
              <p>Mon patrimoine</p>
            </div>

            <ToggleSwitch
              id="sendPatrimony"
              checked={sendPatrimony}
              onChange={() => setSendPatrimony(!sendPatrimony)}
            />
          </div>
          <div className="share">
            <div className="show-pdf" onClick={() => handleOpenPDF("budget")}>
              {loadingOpenBudget && <Loader />}
              {!loadingOpenBudget && <PDFIcon />}
              <p>Mon budget</p>
            </div>
            <ToggleSwitch
              id="sendBudget"
              checked={sendBudget}
              onChange={() => setSendBudget(!sendBudget)}
            />
          </div>
          {/* <ConfirmButton
            label="Envoyer"
            onClick={() => sendEmail()}
            disabled={sendBudget || sendPatrimony ? false : true}
            icon={<div />}
            variant="primary"
          ></ConfirmButton> */}
          <Button
            onClick={() => sendEmail()}
            disabled={sendBudget || sendPatrimony ? false : true}
          >
            Envoyer
          </Button>
        </StyledBox>
        <div className="button-group sticky mobile-only">
          <ConfirmButton
            className="cancel-button"
            label="Annuler"
            icon={<CancelRoundIcon />}
            onClick={() =>
              tryCancelMeeting({
                id_meeting: myMeeting!.id_meeting,
                idAdvisor: myMeeting!.advisorId,
              })
            }
            loading={isCanceling}
          ></ConfirmButton>
        </div>
      </Content>
    </Body>
  );
};

export default MyMeeting;
